<template>
    <div class="customers">
        <!-- <el-alert type="info" :closable="false" title="输入条件查询" /> -->
        <el-form inline style="margin-top: 20px">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="customer_search.name" placeholder="姓名" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="customer_search.phone" type="number" placeholder="手机号" />
            </el-form-item>
            <el-form-item label="邀请码" prop="label">
                <el-input v-model="customer_search.label" type="text" placeholder="邀请码" />
            </el-form-item>
            <el-form-item label="添加日期" prop="created_at">
                <el-date-picker v-model="searchDate" type="daterange" range-separator="到" start-placeholder="Start date"
                    end-placeholder="End date" :size="size" />
            </el-form-item>
            <el-form-item>
                <el-button :icon="Search" @click="customer_submit_search">查询</el-button>
            </el-form-item>
        </el-form>
        <el-alert type="info" :closable="false" title="搜索结果列表" />
        <el-table :data="customer_table" size="small" style="width: 100%; margin-top: 10px">
            <el-table-column prop="name" label="姓名" align="center" width="80px" />
            <el-table-column prop="phone" label="手机号" align="center" width="120px" />
            <el-table-column prop="gender" label="性别" align="center" width="60px">
                <template #default="scope">
                    {{ customer_format_gender(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" align="center" width="80px" />
            <el-table-column prop="height" label="身高(cm)" align="center" width="80px" />
            <el-table-column prop="weight" label="体重(kg)" align="center" width="80px" />
            <el-table-column prop="inviter" label="邀请人" align="center" width="80px" />
            <el-table-column prop="habitation" align="center" label="居住地">
                <template #default="scope">
                    {{ habitation_format(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column prop="shipping_address" align="center" label="发货地">
                <template #default="scope">
                    {{ shipping_address_format(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="65px">
                <template #default="scope">
                    <el-button plain size="small" style="width: 40px;"
                        @click="customer_update_dialog(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px; display: flex; justify-content: right;">
            <el-pagination v-model:current-page="customer_pagination.page" :page-size="10" small="small"
                layout="prev, pager, next, jumper, total" v-model:total="customer_pagination.total"
                @current-change="customer_page_changed" />
        </el-row>
        <el-dialog v-model="customer_show_dialog" title="编辑">
            <el-alert type="info" :closable="false" title="填写并提交表单" />
            <el-form ref="formRef" :model="customer_write" label-width="150" :rules="rulers" style="margin-top: 20px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="customer_write.name" placeholder="姓名" />
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="customer_write.phone" placeholder="手机号" />
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-select v-model.number="customer_write.gender">
                        <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="年龄" prop="age">
                    <el-input v-model.number="customer_write.age" type="number" placeholder="年龄" />
                </el-form-item>
                <el-form-item label="身高" prop="height">
                    <el-input v-model.number="customer_write.height" type="number" placeholder="身高" />
                </el-form-item>
                <el-form-item label="体重" prop="weight">
                    <el-input v-model.number="customer_write.weight" type="number" placeholder="体重" />
                </el-form-item>
                <el-form-item label="邀请人" prop="inviter">
                    <el-input v-model="customer_write.inviter" placeholder="邀请人" />
                </el-form-item>
                <el-form-item label="居住地" prop="habitation">
                    <el-cascader placeholder="" v-model="customer_write.habitation" :options="address_china" :props="props"
                        style="width: 300px" />
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="customer_write.address" placeholder="详细地址" style="width: 300px;" />
                </el-form-item>
                <el-form-item label="发货地址" prop="shipping">
                    <el-cascader placeholder="" v-model="customer_write.shipping" :options="address_china" :props="props"
                        style="width: 300px" />
                </el-form-item>
                <el-form-item label="详细地址" prop="shipping_detail">
                    <el-input v-model="customer_write.shipping_detail" placeholder="详细地址" style="width: 300px;" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="customer_show_dialog = false">取消</el-button>
                    <el-button @click="customer_submit_write(formRef)">编辑</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import axiosIns from '@/libs/axios'
import addressData from '@/assets/provinces.json'

const address_china = addressData.data
const formRef = ref(null)
// 列表展示内容为姓名，手机号，性别，年龄，身高，体重，地址、发货地址
const genderList = reactive([
    { label: '男', value: 1 },
    { label: '女', value: 2 }
])
// 级联选择器次级菜单打开方式
const props = { expandTrigger: 'click' }
const customer_show_dialog = ref(false)
const customer_table = ref([])
const searchDate = ref([])
// 搜索参数
const customer_search = reactive({
    name: null,
    phone: null,
    label: null,
    start: null,
    end: null
})
// 提交编辑数据
const customer_write = reactive({
    id: undefined,
    name: '',
    gender: null,
    phone: null,
    age: 0,
    height: 0,
    weight: 0,
    habitation: [],
    address: '',
    shipping_address: '',
    shipping: [],
    shipping_detail: '',
    inviter: ''
})
const rulers = {
    name: [{ required: true, message: '此项必填', trigger: 'blur' }],
    phone: [{ required: true, pattern: '^1\\d{10}$', message: '手机号格式错误' }],
    gender: [{ required: true, message: '此项必填', trigger: 'blur' }],
    age: [{ required: true, message: '年龄0~150岁', trigger: 'blur' }]
}
// 分页数据
const customer_pagination = reactive({
    page: 1,
    pageSize: 10,
    total: 0
})

// 编辑客户数据回响
const customer_update_dialog = (val) => {
    Object.assign(customer_write, val)
    customer_write.habitation = val.habitation ? val.habitation.split('-') : []
    const arr = val.shipping_address ? val.shipping_address.split('-') : []
    if (arr.length > 2) {
        customer_write.shipping = [arr[0], arr[1], arr[2]]
        customer_write.shipping_detail = arr[3]
    }
    customer_show_dialog.value = true
}

const validate = (formRef, callback) => {
    formRef.validate(valid => {
        if (valid) callback()
    })
}

// 提交编辑表单
const customer_submit_write = async (formRef) => {
    validate(formRef, async () => {
        const postData = JSON.parse(JSON.stringify(customer_write))
        postData.habitation = postData.habitation.join('-')
        postData.shipping_address = postData.shipping.join('-') + '-' + postData.shipping_detail
        try {
            const response = await axiosIns.put('/users', postData)
            customer_show_dialog.value = false
            customer_submit_search()
        } catch (error) {
            console.log(error)
        }
    })
}

const customer_format_gender = (val) => {
    return val.gender == '1' ? '男' : '女'
}

const habitation_format = val => {
    return String(val.habitation).split('-').join('')
}

const shipping_address_format = val => {
    return String(val.shipping_address).split('-').join('')
}

const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null
    const date = new Date(dateTimeString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    return formattedDateTime
}
// 搜索
const customer_submit_search = async () => {
    if (searchDate.value.length == 2) {
        customer_search.start = formatDateTime(searchDate.value[0])
        customer_search.end = formatDateTime(searchDate.value[1])
    }
    // console.log('seach', customer_search)
    const { data, total } = await axiosIns.post('/users/gets', { ...customer_search, ...customer_pagination })
    customer_table.value = data
    customer_pagination.total = total
    console.log('page', customer_pagination)
}

onMounted(() => {
    customer_submit_search()
})

const customer_page_changed = (page) => {
    customer_pagination.page = page
    // console.log('page1 :>> ', page)
    customer_submit_search()
    // console.log('page2 :>> ', customer_pagination.page)
}
</script>
<style lang="less" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
</style>
